<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
            <vx-card class="text-center">
                <font-awesome-icon :icon="icon" spin size="3x" /> 
                <h3 class="mt-4">Logging you out. Please Wait.</h3>
            </vx-card>
        </div>
    </div>
</template>

<script>
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { api } from '../../utils/requests';

export default {
    data() {
        return {
            icon: faSpinner
        }
    },
    created() {
        api.logout_native()
    },
    mounted() {
        this.$store.dispatch('auth/logout')
    }
}
</script>